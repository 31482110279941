var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.items.length === 0)?[(_vm.isLoading)?_c('div',{staticClass:"user-nfts"},[_vm._l((12),function(_){return [_vm._m(0,true)]})],2):_c('div',{staticClass:"user-nfts-empty-panel",domProps:{"textContent":_vm._s(_vm.$t('address.nfts.no_nfts'))}})]:_c('section',{staticClass:"user-nfts"},_vm._l((_vm.items),function(item,idx){return _c('router-link',{key:item.id,staticClass:"user-nft",attrs:{"to":{
                name: item.kind === 'DnsItem' ? 'address' : 'nft',
                params: {
                    address: item.address,
                    skeletonHint: 'item',
                },
            }}},[_vm._o(_c('div',{staticClass:"user-nft__image"},[(item.image)?[(item.image.type === 'NftContentImage' && item.image.thumb && item.image.thumb.sized)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":item.image.thumb.sized,"loading":idx > 4 ? 'lazy' : 'eager',"alt":item.name}}):(item.image.type === 'NftContentLottie' && item.image.preview && item.image.preview.sized)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":item.image.preview.sized,"loading":idx > 4 ? 'lazy' : 'eager',"alt":item.name}}):(item.image.type === 'NftContentVideo' && item.image.cover)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":item.image.cover,"loading":idx > 4 ? 'lazy' : 'eager',"alt":item.name}}):_vm._e()]:_vm._e()],2),0,item.id),_vm._v(" "),_vm._o(_c('footer',{staticClass:"user-nft__footer"},[(item.collection)?_c('router-link',{staticClass:"user-nft__collection",attrs:{"to":{
                        name: 'nft',
                        params: {
                            address: item.collection.address,
                            skeletonHint: 'collection',
                        },
                    }}},[_c('span',{staticClass:"user-nft__collection__text",domProps:{"textContent":_vm._s(item.collection.name)}}),_vm._v(" "),(item.collection.isVerified)?_c('icon-verified',{staticClass:"user-nft__collection__icon",attrs:{"viewBox":"0 0 16 16"}}):_vm._e()],1):_c('span',{staticClass:"user-nft__collection",domProps:{"textContent":_vm._s(_vm.$t('address.nfts.no_collection'))}}),_vm._v(" "),_c('h3',{staticClass:"user-nft__name",domProps:{"textContent":_vm._s(item.name)}})],1),1,item.id)])}),1),_vm._v(" "),_c('mugen-scroll',{staticClass:"mugen-scroll",staticStyle:{"margin-bottom":"6px"},attrs:{"handler":_vm.loadMore,"should-handle":_vm.shouldHandleScroll}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreloader),expression:"showPreloader"}],ref:"infiniteLoader",staticClass:"mugen-scroll__button",on:{"click":_vm.loadMore}},[(_vm.isLoading)?[_vm._v(_vm._s(_vm.$t('common.loading')))]:[_vm._v(_vm._s(_vm.$t('common.load_more')))]],2)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{pre:true,attrs:{"class":"user-nft user-nft--skeleton"}},[_c('div',{pre:true,attrs:{"class":"user-nft__image"}},[_c('div',{pre:true,attrs:{"class":"user-nft__image__img"}})]),_vm._v(" "),_c('footer',{pre:true,attrs:{"class":"user-nft__footer"}},[_c('h4',{pre:true,attrs:{"class":"user-nft__collection"}},[_c('span',{pre:true,attrs:{"class":"user-nft__collection__text skeleton"}},[_vm._v("NFT collection name")])]),_vm._v(" "),_c('h3',{pre:true,attrs:{"class":"user-nft__name"}},[_c('span',{pre:true,attrs:{"class":"skeleton"}},[_vm._v("NFT name")])])])])
}]

export { render, staticRenderFns }